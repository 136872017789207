<template>
  <div>
    <div class="footer" >
      <span @click="goBeian"  >闽ICP备19006430号-2</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomFooter",
  props: {},
  mounted() {},
  methods: {
    goBeian() {
      window.open('https://www.beian.miit.gov.cn/');
    }
  }
};
</script>
<style lang="less" scoped>
.footer {
  text-align: center;
  span {
    cursor: pointer;
  }
}
</style>