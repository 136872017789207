import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/login.vue')
    },
    {
        path: '/mobileGame',
        name: 'MobileGame',
        meta: {
            title: '破晓'
        },
        component: () =>
            import ('../views/mobileGame/mobileGame.vue')
    },
    {
        path: '/city',
        name: 'City',
        meta: {
            title: '智慧城市'
        },
        component: () =>
            import ('../views/smartCity/city.vue')
    },
    {
        path: '/KmLogin',
        name: 'KmLogin',
        meta: {
            title: '可萌中心登入'
        },
        component: () =>
            import ('../views/kmCenter/kmLogin.vue')
    },
    {
        path: '/kmSystemCenter',
        name: 'KmSystemCenter',
        meta: {
            title: '可萌数据中心',
            desc: '菜单入口页',
            keepAlive: true
        },
        component: () =>
            import ('../views/kmCenter/kmSystemCenter.vue')
    },
    {
        path: '/kmConsumerList',
        name: 'KmConsumerList',
        meta: {
            title: '可萌数据中心',
            desc: '七周年用户'
        },
        component: () =>
            import ('../views/kmCenter/list/kmConsumerList.vue')
    },
    {
        path: '/vipList',
        name: 'vipList',
        meta: {
            title: '可萌数据中心',
            desc: '会员用户'
        },
        component: () =>
            import ('../views/kmCenter/list/vipList.vue')
    },
    {
        path: '/eightConsumerList',
        name: 'EightConsumerList',
        meta: {
            title: '可萌数据中心',
            desc: '八周年单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/eightConsumerList.vue')
    },
    {
        path: '/nineConsumerList',
        name: 'NineConsumerList',
        meta: {
            title: '可萌数据中心',
            desc: '九周年单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/nineConsumerList.vue')
    },
    {
        path: '/tenthConsumerList',
        name: 'TenthConsumerList',
        meta: {
            title: '可萌数据中心',
            desc: '十周年单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/tenthConsumerList.vue')
    },
    {
        path: '/vipServeUser',
        name: 'VipServeUser',
        meta: {
            title: '可萌数据中心',
            desc: '八周年单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/vipServeUser.vue')
    },
    {
        path: '/singlePartList',
        name: 'SinglePartList',
        meta: {
            title: '可萌数据中心',
            desc: '七周年单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/singlePartList.vue')
    },
    {
        path: '/innerSinglePartList',
        name: 'InnerSinglePartList',
        meta: {
            title: '可萌数据中心',
            desc: '店内单次套系'
        },
        component: () =>
            import ('../views/kmCenter/list/innerSinglePartList.vue')
    },
    {
        path: '/innerGrowUpList',
        name: 'InnerGrowUpList',
        meta: {
            title: '可萌数据中心',
            desc: '店内成长套系'
        },
        component: () =>
            import ('../views/kmCenter/list/innerGrowUpList.vue')
    },
    {
        path: '/productionList',
        name: 'ProductionList',
        meta: {
            title: '可萌数据中心',
            desc: '店内成长套系'
        },
        component: () =>
            import ('../views/kmCenter/list/productionList.vue')
    },
    {
        path: '/h5ConsumerList',
        name: 'H5ConsumerList',
        meta: {
            title: '可萌数据中心',
            desc: '七周年H5版本'
        },
        component: () =>
            import ('../views/kmCenter/H5list/h5ConsumerList.vue')
    },
    {
        path: '/h5SinglePartList',
        name: 'H5SinglePartList',
        meta: {
            title: '可萌数据中心',
            desc: '七周年单套系H5'
        },
        component: () =>
            import ('../views/kmCenter/H5list/h5SinglePartList.vue')
    },
    {
        path: '/h5InnerSinglePartList',
        name: 'H5InnerSinglePartList',
        meta: {
            title: '可萌数据中心',
            desc: '店内单套系H5'
        },
        component: () =>
            import ('../views/kmCenter/H5list/h5InnerSinglePart.vue')
    },
    {
        path: '/h5InnerGrowUpList',
        name: 'H5InnerGrowUpList',
        meta: {
            title: '可萌数据中心',
            desc: '店内成长套系H5'
        },
        component: () =>
            import ('../views/kmCenter/H5list/h5InnerGrowUpList.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to.name)

    if (['Home', 'Login'].indexOf(to.name) !== -1) {
        store.commit('changeHeader', true)
    } else {
        store.commit('changeHeader', false)
    }

    let token = localStorage.getItem('token');
    if (!token || token == '') {
        store.commit('changeIsLoginSpace', false);
        //  登入判定路由
        // if(to.name.toLocaleLowerCase() == 'login') {
        //   console.log('走了这里')
        //   next();
        // } else {
        //   next('/login')
        // }
    } else {
        store.commit('changeIsLoginSpace', true);
    }
    next()
})

export default router