<template>
  <div class="headerBody">
    <ul>
      <li>
        <span @click="goHome">Suezp小站</span>
      </li>
      <li>
        <span @click="logout">{{isLoginSpace?'退出登入':'登入'}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HeaderNav",
  props: {},
  data() {
    return {};
  },
  computed:{
    ...mapState(["isLoginSpace"])
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push({
        name: "Login",
      });
    },
    goHome() {
      this.$router.push({
        name: "Home",
      });
    }
  },
  mounted() {
    console.log(this.isLoginSpace)
  },
};
</script>

<style scoped lang="less">
.headerBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  height: 40px;
  line-height: 40px;;
  padding: 0px;
  width: 100%;
  color: #f7f7f7;
  font-size: 15px;
  li {
    height: 100%;
    padding: 0 10px;
    min-width: 50px;
    text-align: center;
    list-style: none;
    background-color: #00000094;
    border-right: 1px solid rgb(199, 194, 194);
    span {
      cursor: pointer;
      display: inline-block;
    }
  }
  li:nth-last-child(1){
    border-right: none;
  }
}
</style>
