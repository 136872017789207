import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex)

const state = {
    count: 0,
    name: 'suezp',
    showHeader: false,
    isLoginSpace: false
}
const mutations = {
    increment: (state) => {
        state.count++
    },
    changeName: (state, param) => {
        state.name += '__' + param
    },
    changeHeader: (state, param) => {
        state.showHeader = param
    },
    changeIsLoginSpace: (state,param) =>{
        state.isLoginSpace = param
    }
}

export default new Vuex.Store({
    state,
    mutations
})