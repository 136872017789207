<template>
  <div class="home">
    <section class="mainContent">
      <div class="searchBox">
        <input type="search" @keyup.enter="search" placeholder="输入想要搜索的内容....">
        <i class="el-icon-search" style="background:#0000006b;font-weight:600;" @click="search" ></i>
      </div>
      <div class="fixBox"></div>
      <div class="quickBox">
        <ul>
          <li v-for="item in quickList" :key="item.name" @click="goSrc(item.src)">
            <div class="iconBox">
              <span class="imgSpan" >
                <img :src="'https://suezp.cn/server/'+item.imgUrl" alt="">
              </span>
            </div>
            <div>{{item.name}}</div>
          </li>
        </ul>
      </div>
    </section>
    <div class="footer" >
      <span @click="goBeian"  >闽ICP备19006430号-2</span>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      quickList: [
        { 
          name:'百度',
          imgUrl:'baidu.png',
          src: 'https://baidu.com'
        },
        { 
          name:'掘金',
          imgUrl:'juejin.svg',
          src: 'https://juejin.cn'
        },
        { 
          name:'哔哩哔哩',
          imgUrl:'bilibili.png',
          src: 'https://www.bilibili.com/'
        },
        { 
          name:'可萌中心',
          imgUrl:'kmProfile.png',
          src: 'https://suezp.cn/#/kmsystemcenter'
        },
        { 
          name:'3D地图',
          imgUrl:'/building/building_1.png',
          src: 'https://suezp.cn/#/city'
        },
        { 
          name:'H5游戏破晓',
          imgUrl:'moto.png',
          src: 'https://suezp.cn/#/mobileGame'
        },
      ],
    };
  },
  computed: {
    ...mapState(["count", "name"]),
  },
  mounted() {},
  methods: {
    goBeian() {
      window.open('http://www.beian.gov.cn/');
    },
    goSrc(src) {
      window.location.href = src;
    },
    search() {
      console.log('搜索');
    }
  },
};
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// body::-webkit-scrollbar {
//   width: 0rem;
//   height: 0.5rem;
//   background: rgba(255, 255, 255, 0.39);
// }
// body::-webkit-scrollbar-thumb {
//   border-radius: 0;
//   background-color: #025a6ccc;
//   -webkit-transition: background-color 0.15s;
//   transition: background-color 0.15s;
// }
// body::-webkit-scrollbar-track {
//   border-radius: 0;
// }
.home {
  padding-top: 40px;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  overflow-y: auto;
  color: #0f0a0a;
  background: url("https://suezp.cn/server/kmbj.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    // border: 1px solid #fff;
    color: rgb(219, 209, 209);
    .searchBox {
      display: flex;
      margin: 80px auto 0;
      height: 50px;
      width: 54%;
      min-width: 500px;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.295);
      input {
        flex: 1;
        padding: 0 20px;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0);
      }
      i {
        height: 100%;
        width: 50px;
        color: #0072c9;
        line-height: 50px;
        text-align: center;
        font-size: 22px;
        cursor: pointer;
      }
    }
    .fixBox {
      flex: 1;
    }
    .quickBox {
      position: relative;
      margin: 0px auto 50px;
      height: 90px;
      min-width: 800px;
      width: 90%;
      ul {
        height: 100%;
        display: block;
        li {
          padding-top: 10px;
          margin: 0 10px;
          color: #000;
          display: inline-block;
          width: 120px;
          height: 100%;
          border-radius: 3px;
          transition: all .1s ease-in-out 0s;
          cursor: pointer;
          .iconBox {
            background-color: #fff;
            height: 44px;
            width: 44px;
            margin: 0 auto;
            border-radius: 3px;
            padding: 7px;
            box-sizing: border-box;
            overflow: hidden;
            .imgSpan {
              height: 30px;
              width: 30px;
              border-radius: 2px;
              overflow: hidden;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          div:nth-child(2) {
            font-size: 14px;
            color: #fff;
            text-align:center;
            line-height: 20px;
            height: 20px;
            width: 100%;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        li:hover {
          background-color: rgba(155, 155, 155, 0.582);
        }
      }
    }
  }
}

// 兼容H5
@media screen and (max-width: 540px) {
  .home .mainContent{
    .searchBox {
      width: 100%;
      min-width: 300px;
    }
    .quickBox {
      width: 100%;
      min-width: 300px;
      overflow-x: auto;
      ul {
        width: 600px;
        li {
          width: 90px;
        }
      }
    }
  }
}
.footer {
  width: 100%;
  position: relative;
  bottom: 10px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  span {
    cursor: pointer;
  }
}
</style>