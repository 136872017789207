<template>
  <div id="app">
    <div id="nav" v-if="showHeader">
      <header-nav></header-nav>
    </div>
    <main id="flexContain">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" id="main" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" id="main" />
    </main>
    <bottom-footer id="footer" v-if="false"></bottom-footer>
  </div>
</template>
<script>
import HeaderNav from "./components/HeaderNav.vue";
import { mapState } from "vuex";
import BottomFooter from "./components/BottomFooter.vue";
export default {
  components: {
    HeaderNav,
    BottomFooter,
  },
  computed: {
    ...mapState(["showHeader"]),
  },
  mounted() {
    // this.banhandleScale();
  },
  methods: {
    // 禁止双击放大 以及 横屏自动放大
    banhandleScale() {
      // 双击放大 横屏自动放大
      document.documentElement.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
      //  双指放大
      // var lastTouchEnd = 0;
      // document.documentElement.addEventListener(
      //   "touchend",
      //   function (event) {
      //     var now = Date.now();
      //     if (now - lastTouchEnd <= 300) {
      //       event.preventDefault();
      //     }
      //     lastTouchEnd = now;
      //   },
      //   { passive: false }
      // );
      // 横屏自动放大
      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          var i = document.getElementsByTagName("meta");
          i[1]["content"] =
            "width=device-width,initial-scale=1,maximum-scale=2,minimum-scale=1";
        },
        { passive: false }
      );
    },
  },
};
</script>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #fff;
}
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  color: #2c3e50;
}
#flexContain {
  padding: 0px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

#footer {
  position: absolute;
  bottom: 10px;
  color: #000;
  width: 100%;
}

#nav {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* 手机适配 */
@media screen and (max-width: 540px) {
  #nav {
    zoom: 0.5;
  }
  .leftShowBox {
    display: none !important;
  }
  .mainPlace {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 280px) {
  #nav {
    zoom: 0.4;
  }
  .leftShowBox {
    display: none !important;
  }
  .mainPlace {
    margin-left: 0px !important;
  }
}
.el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell,
.el-form--inline .el-form-item__label {
  color: #000;
}
.el-table th.is-leaf {
  background-color: #ebf1ff;
}

/* 可萌样式 */
.pageTopic {
  min-width: 120px;
  text-align: center;
  cursor: default;
  display: inline-block;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 2px;
  background: #42bfac;
  color: #fff;
}
.dialogBox {
  background-color: #dbdde1!important;
  .el-dialog__body {
    padding-top: 10px;
  }
}

.el-popover {
  background: #efefef!important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 40%)!important;
}

</style>
