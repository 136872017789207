import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import router from './router/index.js'
import Vuex from 'vuex'
import Viewer from 'v-viewer'
import ElementUI from 'element-ui';
import axios from 'axios';
import 'element-ui/lib/theme-chalk/index.css';
import 'viewerjs/dist/viewer.css'
import Vant from 'vant';
import 'vant/lib/index.css';


// Vue.prototype.$baseUrl = process.env.NODE_ENV == "development" ? 'http://127.0.0.1:1111' : 'https://suezp.cn/server';
Vue.prototype.$baseUrl = 'https://suezp.cn/server';
// console.log('当前环境', process.env.NODE_ENV, Vue.prototype.$baseUrl)

Vue.use(Vant);
Vue.use(Viewer)
Vue.use(ElementUI)
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.prototype.$store = store
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : 'Suezp小站';
    next();
})

let token = localStorage.getItem('token');
axios.defaults.headers.Authorization = token ? token : '';
axios.interceptors.response.use(res => {
    // 请求成功对响应数据做处理
    if (res.data.code === 401) {
        localStorage.removeItem('token');
        setTimeout(() => {
            router.push('/kmLogin')
        }, 200);
    }
    // 该返回的数据则是axios.then(res)中接收的数据
    return res
}, err => {
    // 在请求错误时要做的事儿

    // 该返回的数据则是axios.catch(err)中接收的数据
    return Promise.reject(err)
})

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')